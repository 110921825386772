import $ from "jquery";

//Style loader
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "animate.css/animate.min.css";
import "swiper/dist/css/swiper.min.css";
import "jquery-datetimepicker/build/jquery.datetimepicker.min.css";
import "../styles/index.scss";

//JS loader
import "bootstrap";
import "owl.carousel";
import "jquery-touchswipe";
import "jquery-datetimepicker";
import "jquery-validation";

import Swiper from "swiper/dist/js/swiper.min";
import axios from "axios";

window.onload = function () {}; //eslint-disable-line
let screenWidth = $(window).width();

function scrollToElement(offset) {
  $("html, body").animate(
    {
      scrollTop: offset,
    },
    500
  );
}

$(document).ready(function () {
  const owl = $(".owl-carousel");

  owl.on("initialized.owl.carousel", function (event) {
    const index = event.item.index;
    let itemIndex = index + 1;
    if (screenWidth < 600) {
      itemIndex = index;
    }
    const title = $(".owl-stage .owl-item").eq(itemIndex).find(".item").attr("data-title");

    let html = $(".owl-stage .owl-item").eq(itemIndex).find(".itemDetail").clone();

    $(".detailServices .container").html(html);
    $(".titleSlide").text(title);
    $(".owl-stage .owl-item").eq(itemIndex).addClass("currentItemResize");
  });

  let startPosition = 0;
  if (screenWidth >= 600) {
    startPosition = 3;
  }

  owl.owlCarousel({
    loop: true,
    margin: 0,
    nav: true,
    navText: ["", ""],
    dots: true,
    dotsEach: true,
    startPosition,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      980: {
        items: 3,
      },
    },
  });

  owl.on("changed.owl.carousel", function (event) {
    const index = event.item.index;
    let itemIndex = index + 1;
    if (screenWidth < 600) {
      itemIndex = index;
    }
    let title = $(".owl-stage .owl-item").eq(itemIndex).find(".item").attr("data-title");
    let html = $(".owl-stage .owl-item").eq(itemIndex).find(".itemDetail").clone();
    $(".detailServices .container").html(html);
    $(".titleSlide").text(title);
    $(".owl-stage .owl-item").removeClass("currentItemResize");
    $(".owl-stage .owl-item").eq(itemIndex).addClass("currentItemResize");
  });

  const galleryThumbsElement = $(".gallery-thumbs");
  const galleryTopElement = $(".gallery-top");
  if (galleryThumbsElement.length > 1) {
    for (let i = 0; i < galleryThumbsElement.length; i++) {
      let galleryThumbs = new Swiper($(galleryThumbsElement[i]), {
        spaceBetween: 5,
        slidesPerView: 6,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        breakpoints: {
          480: {
            slidesPerView: 5,
          },
        },
      });

      new Swiper($(galleryTopElement[i]), {
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: galleryThumbs,
        },
      });
    }
  }

  $(".popupHours").bind("click tap", function () {
    $("body").addClass("open-popup ");
  });

  $(".popup .close").bind("click tap", function () {
    $("body").removeClass("open-popup");
  });
  $(".open-popup-booking .close").bind("click tap", function () {
    $("body").removeClass("open-popup-booking");
  });

  $("#dropdownMenuButton").bind("click", function () {
    $(this).parents(".dropdown").find(".dropdown-menu").toggle();
  });
  $(".dropdown-item").bind("click", function () {
    let href = $(this).attr("href");
    $(this).parents(".dropdown").find(".dropdown-menu").toggle();
    scrollToElement($(href).offset().top);
  });

  $("header .nav nav ul.leftMenu li a, header .nav nav ul.rightMenu li a, a.bookingNow").bind("click", function (e) {
    e.preventDefault();
    let href = $(this).attr("href");
    scrollToElement($(href).offset().top);
  });

  const width = $(window).width();
  if (width >= 768) {
    $(".nav-tabs")
      .find("a")
      .on("show.bs.tab", function () {
        let tab = $(this).attr("aria-controls");
        $(this).parents(".nav-tabs").attr("data-tabActive", tab);
      });
  } else {
    const navTab = $("#nav-tab");
    navTab.owlCarousel({
      margin: 0,
      nav: true,
      navText: ["", ""],
      dots: false,
      startPosition: 1,
      dotsEach: false,
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 1,
        },
      },
    });

    navTab.on("changed.owl.carousel", function (event) {
      const index = event.item.index;
      $("#nav-tabContent").find("> div").removeClass("show active").hide();
      $("#nav-tabContent").find("> div").eq(index).addClass("show active").show();
    });
  }
  $("#datetimepicker").datetimepicker({
    formatDate: "d/m/Y",
    formatTime: "H:i",
    minDate: 0,
  });

  /*$(window).scroll(function(e) {
    var offsetPage = $(window).scrollTop();
    const offset1 = $('.imgMain').offset().top;
    var offset2 = $('.bgPresent').offset().top;
    var offset3 = $('.bgPresent1').offset().top;
    var  pos1 = offset1/offsetPage > 1 ? offset1/offsetPage * 15 : offsetPage <= offset1 - offsetPage ? 0 : 0;
    var  pos2 = offset2/offsetPage > 1 ? offset2/offsetPage * 5 : offsetPage <= offset2 - offsetPage ? 0 : 0;
    var  pos3 = offset3/offsetPage > 1 ? offset3/offsetPage * 5 : offsetPage <= offset3 - offsetPage ? 0 : 0;

    $('.imgMain').css({
      '-webkit-transform': 'translate(50%, ' + pos1 + 'px)',
      'transform': 'translate(-50%, ' + pos1 + 'px)'
    });
    $('.bgPresent').css({
      '-webkit-transform': 'translate(0px, ' + pos2 + 'px)',
      'transform': 'translate(0px, ' + pos2 + 'px)'
    });
    $('.bgPresent1').css({
      '-webkit-transform': 'translate(0px, ' + pos3 + 'px)',
      'transform': 'translate(0px, ' + pos3 + 'px)'
    });
  })*/

  const btn = $("#booking-btn");
  $("#booking-form").validate({
    rules: {
      // simple rule, converted to {required:true}
      name: {
        required: true,
        minlength: 3,
        maxlength: 30,
      },
      phone: {
        required: true,
      },
      datetime: {
        required: true,
      },
    },
    submitHandler: (form) => {
      btn.attr("disabled", "disabled");
      btn.html("Booking...");
      axios
        .post("https://us-central1-bnb-nails-b988a.cloudfunctions.net/booking", {
          name: form.name.value,
          phone: form.phone.value,
          datetime: form.datetime.value,
          detail: form.detail.value,
        })
        .then(function () {
          $("body").addClass("open-popup-booking");
          btn.removeAttr("disabled");
          btn.html("Complete Booking");
          form.reset();
          $(".open-popup-booking .close").bind("click tap", function () {
            $("body").removeClass("open-popup-booking");
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });
});

window.onresize = function () {
  screenWidth = $(window).width();
}; //eslint-disable-line
